/*
 * Module three-column-with-text-and-associated-image (three-column-with-text-and-associated-image)
 * Added by karolinanorstrom, 2024-02-13T12:23:16.657Z
 *
 * This file is used on both desktop and mobile.
 */
section.three-column-with-text-and-associated-image .heading-container p {
  width: 100%;
  padding: 0 40px;
}
section.three-column-with-text-and-associated-image .image-container {
  flex-direction: column;
  gap: 70px;
}
section.three-column-with-text-and-associated-image .image-container .group-container {
  width: 100%;
}