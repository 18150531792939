/*
 * Module text-section-and-img (text-section-and-img)
 * Added by karolinanorstrom, 2024-02-21T12:32:47.669Z
 *
 * This file is used on both desktop and mobile.
 */
section.text-section-and-img {
  display: block !important;
}
section.text-section-and-img .heading-container {
  width: 100%;
  margin-left: 0px;
  margin-bottom: 24px;
}
section.text-section-and-img .image-container {
  width: 100%;
}