/*
 * Module how-does-it-work-block (how-does-it-work-block)
 * Added by karolinanorstrom, 2024-02-16T09:14:37.118Z
 *
 * This file is used on both desktop and mobile.
 */
section.how-does-it-work-block .outer-container .spacer {
  margin: 26px auto 100px;
}
section.how-does-it-work-block .outer-container h1 {
  font-size: var(--h2-size);
}
section.how-does-it-work-block .outer-container .inner-container {
  margin-left: 120px;
  margin-right: 0;
  width: calc(100% - 120px);
}
section.how-does-it-work-block .outer-container .right-block {
  align-items: flex-start;
  padding-bottom: 80px;
  position: relative;
}
section.how-does-it-work-block .outer-container .left-block {
  padding-bottom: 80px;
  position: relative;
}
section.how-does-it-work-block .outer-container .left-block .last-line {
  left: 30px;
}
section.how-does-it-work-block .outer-container .left-block .last-line .circle {
  top: 0px;
}
section.how-does-it-work-block .outer-container .vline {
  left: 30px;
  height: 100%;
}