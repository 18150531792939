/*
 * Module image-and-text-full-width-block (image-and-text-full-width-block)
 * Added by karolinanorstrom, 2024-02-14T10:47:23.148Z
 *
 * This file is used on both desktop and mobile.
 */
section.image-and-text-full-width-block .text-container {
  display: block;
}
section.image-and-text-full-width-block .text-container p {
  width: 100%;
}