/*
 * Module suppliers-section (suppliers-section)
 * Added by viktorandersson, 2024-02-22T12:53:43.154Z
 *
 * This file is used on both desktop and mobile.
 */
section.suppliers-section .suppliers-container a {
  display: block;
  max-width: calc(33.33% - 20px);
}
section.suppliers-section .suppliers-container a img {
  width: 100%;
  display: block;
}