/*
 * Module two-column-text-and-image-right (two-column-text-and-image-right)
 * Added by karolinanorstrom, 2024-02-13T12:48:50.625Z
 *
 * This file is used on both desktop and mobile.
 */
section.two-column-text-and-image-right {
  margin-left: 20px;
  margin-right: 20px;
  display: block !important;
  text-align: center;
}
section.two-column-text-and-image-right .heading-container {
  width: 100%;
  display: block;
  padding-bottom: 24px;
}
section.two-column-text-and-image-right .heading-container h2 {
  color: var(--mobile-h1-color);
  font-size: var(--mobile-h1-size);
}
section.two-column-text-and-image-right .image-container {
  width: 100%;
  display: block;
}