/*
 * Module Hero (hero)
 * Added by karolinanorstrom, 2024-02-05T11:18:02.142Z
 *
 * This file is used on both desktop and mobile.
 */
section.hero .content-container {
  padding-bottom: 0px;
}
section.hero .hero-container {
  background-image: none !important;
  height: fit-content;
}
section.hero .hero-container .banner-roll.desktop {
  display: none;
}
section.hero .hero-container .reference-year {
  display: none;
}
section.hero .hero-container .hero-left {
  width: 100%;
}
section.hero .hero-container .hero-right {
  display: none;
}
section.hero .hero-container .hero-container-mobile {
  height: 250px;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}
section.hero .hero-container .hero-container-mobile .reference-year-mobile {
  color: var(--h1-color);
  font-size: var(--mobile-h1-size);
  font-family: var(--h1-font);
  line-height: 110%;
  letter-spacing: -1px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
}
section.hero .hero-container .hero-container-mobile .hero-text-container h1 {
  color: var(--h1-color);
  padding-top: 10%;
  font-size: var(--h1-size);
  font-family: var(--h1-font);
  padding-bottom: 25px;
  line-height: 110%;
  letter-spacing: -1px;
  width: 100%;
}
section.hero .hero-container h1 {
  color: var(--mobile-h1-color);
  font-size: var(--mobile-h1-size);
  padding-top: 16px;
}
section.hero .hero-container p {
  color: var(--mobile-p1-color);
  font-family: var(--p1-font);
  font-weight: 500;
  font-size: var(--p2-size);
  margin-right: 10%;
}