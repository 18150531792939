/*
 * Module three-column-text-and-two-images (three-column-text-and-two-images)
 * Added by karolinanorstrom, 2024-02-13T13:22:02.916Z
 *
 * This file is used on both desktop and mobile.
 */
section.three-column-text-and-two-images {
  display: block !important;
}
section.three-column-text-and-two-images .content-container {
  display: block;
}
section.three-column-text-and-two-images .heading-container {
  width: 100%;
}
section.three-column-text-and-two-images .image-container {
  margin-top: 24px;
  width: 100%;
}