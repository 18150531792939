/*
 * Module three-column-two-text-items-and-img (three-column-two-text-items-and-img)
 * Added by karolinanorstrom, 2024-02-21T11:22:53.237Z
 *
 * This file is used on both desktop and mobile.
 */
section.three-column-two-text-items-and-img {
  display: block !important;
}
section.three-column-two-text-items-and-img .heading-container {
  width: 100%;
  margin-bottom: 24px;
}
section.three-column-two-text-items-and-img .image-container {
  width: 100%;
  display: block;
}
section.three-column-two-text-items-and-img .image-container .image-container-inner {
  width: 100%;
}