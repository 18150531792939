/*
 * Module ongoing-project (ongoing-project)
 * Added by karolinanorstrom, 2024-02-06T13:24:10.801Z
 *
 * This file is used on both desktop and mobile.
 */
section.ongoing-project .ongoing-project-container {
  display: block;
  height: 100%;
}
section.ongoing-project .ongoing-project-container .image-container {
  display: flex;
}
section.ongoing-project .ongoing-project-container .image-container .first-image-container {
  width: 100%;
}
section.ongoing-project .ongoing-project-container .text-container {
  width: 100%;
  padding: 0px;
}
section.ongoing-project .ongoing-project-container .text-container h2 {
  color: var(--mobile-h1-color);
  font-size: var(--mobile-h1-size);
  margin-top: 16px;
}
section.ongoing-project .ongoing-project-container .text-container h2 .services-container {
  font-size: 14px;
}