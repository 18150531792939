/*
 * Module img-and-text-section (img-and-text-section)
 * Added by karolinanorstrom, 2024-02-12T20:07:41.243Z
 *
 * This file is used on both desktop and mobile.
 */
section.img-and-text-section {
  display: block !important;
}
section.img-and-text-section .image-container {
  width: 100%;
}
section.img-and-text-section .heading-container {
  margin-left: 0px;
  width: 100%;
  margin-top: 36px;
}