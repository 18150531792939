/*
 * Module reference-logotype-section (reference-logotype-section)
 * Added by karolinanorstrom, 2024-04-16T13:09:32.790Z
 *
 * This file is used on both desktop and mobile.
 */
section.reference-logotype-section .reference-logo-container a {
  display: block;
  max-width: calc(33.33% - 20px);
}
section.reference-logotype-section .reference-logo-container a img {
  width: 100%;
  display: block;
}